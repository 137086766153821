<template>
  <div class="container">
    <div class="current-position">
      当前位置：
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/bm/list'}">我的课程</el-breadcrumb-item>
        <el-breadcrumb-item>{{ returnLabel() }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-row :gutter="20" style="margin-top: 44px">
      <el-col :span="3" :xs="24">
        <el-tabs tab-position="left" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="item in menuOptions" :label="item.label" :name="item.value" :key="item.value"/>
        </el-tabs>
      </el-col>
      <el-col :span="20" :xs="24">
        <router-view/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOptions: [
        {label: '直播课程', value: 'live'},
        {label: '点播课程', value: 'recorded'},
        {label: '我的证书', value: 'certificate'},
      ],
      activeName: null
    };
  },
  methods: {
    handleClick(e) {
      this.$router.push({path: `/bm/list/${e.name}`})
    },
    returnLabel() {
      const list = this.menuOptions.find(item => item.value === this.activeName)
      return list?.label
    }
  },

  mounted() {
    this.activeName = this.$route.name
  },
  watch: {
    '$route'(to) {
      this.activeName = to.name
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep.container {
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  background: #FFFFFF !important;
  padding-top: 44px;

  .current-position {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
